<template>
    <section class="mb-12 filters-container">
        <h3 class="pb-1 border-b">
            {{ $t('calendar.filters') }}
        </h3>

        <ClientsDropdown
            withArchive
            multiple
            :collapseTags="false"
            class="w-full mt-3"
            @selected="filtersChanged"
        />

        <EmployeesDropdown
            :withArchive="true"
            :multiple="true"
            :customClass="'w-full mt-3'"
            @selected="filtersChanged"
        />

        <ServicesDropdown class="w-full mt-3" @change="filtersChanged" />

        <el-select
            v-model="selectedClientsManagers"
            class="w-full mt-3"
            :placeholder="$t('calendar.clients_by_manager')"
            multiple
            filterable
            @change="filtersChanged"
        >
            <el-option
                v-for="manager in managersList"
                :key="manager.uuid"
                :label="manager.name + ' ' + manager.surname"
                :value="manager.uuid"
            />
        </el-select>

        <el-select
            v-model="selectedEmployeesManagers"
            class="w-full mt-3"
            :placeholder="$t('calendar.employees_by_manager')"
            multiple
            filterable
            @change="filtersChanged"
        >
            <el-option
                v-for="manager in managersList"
                :key="manager.uuid"
                :label="manager.name + ' ' + manager.surname"
                :value="manager.uuid"
            />
        </el-select>

        <el-checkbox v-model="withoutEmployees" class="mt-3" trueLabel="1" falseLabel="0" @change="filtersChanged">
            <span>{{ $t('calendar.without_employees') }}</span>
        </el-checkbox>

        <el-checkbox v-model="cancelled" class="mt-3" trueLabel="1" falseLabel="0" @change="filtersChanged">
            <span>{{ $t('calendar.cancelled') }}</span>
        </el-checkbox>

        <el-checkbox v-model="onlyWithKeys" class="mt-3" trueLabel="1" falseLabel="0" @change="filtersChanged">
            <span>{{ $t('calendar.with_keys_only') }}</span>
        </el-checkbox>
    </section>
</template>
<script>
import queryString from 'query-string';

export default {
    components: {
        ClientsDropdown:   () => import(/* webpackChunkName: "ClientsDropdown" */ '@/components/ClientsDropdown'),
        EmployeesDropdown: () => import(/* webpackChunkName: "EmployeesDropdown" */ '@/components/EmployeesDropdown'),
        ServicesDropdown:  () => import(/* webpackChunkName: "ServicesDropdown" */ '@/components/ServicesDropdown'),
    },

    data() {
        return {
            onlyWithKeys:              false,
            selectedClientsManagers:   [],
            selectedEmployeesManagers: [],
            withoutEmployees:          false,
            cancelled:                 0,
        };
    },

    computed: {
        managersList() {
            return this.$store.state.managersDropdown;
        },
    },

    created() {
        this.initDataFromUrlParameters();
    },

    methods: {
        filtersChanged() {
            this.updateUrlSearchQuery();
            this.$store.commit('calendar/refreshCalendarEvents');
        },

        updateUrlSearchQuery() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.services = this.selectedServices;
            query.clients_manager_uuid = this.selectedClientsManagers;
            query.employees_manager_uuid = this.selectedEmployeesManagers;
            query.without_employees = this.withoutEmployees;
            query.cancelled = this.cancelled;
            query.only_with_keys = this.onlyWithKeys;
            this.$router.replace({ query }).catch(() => {});
        },

        initDataFromUrlParameters() {
            const query = queryString.parse(window.location.search);
            this.selectedServices = query.services;
            this.selectedClientsManagers = query.clients_manager_uuid ? [query.clients_manager_uuid] : [];
            this.selectedEmployeesManagers = query.employees_manager_uuid ? [query.employees_manager_uuid] : [];
            this.withoutEmployees = query.without_employees || 0;
            this.onlyWithKeys = query.only_with_keys || 0;
            this.cancelled = query.cancelled || 0;
        },
    },
};
</script>
<style>
    .filters-container .el-select__tags-text {
        max-width: 160px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: inherit;
        float: left;
    }
    .el-input__inner::placeholder {
        color: #999999;
    }
</style>
